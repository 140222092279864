<template>
  <div class="about-page">
    <div class="about-container">
      <h1 class="about-title">关于我们</h1>
      <div class="company-intro">
        <div class="feature-cards">
          <div class="feature-card">
            <h3>技术创新</h3>
            <p>微米数字科技，于数字科技浪潮中奋勇争先。聚焦云计算、大数据等前沿，凭借敏锐洞察与创新思维，将新兴技术深度融合，持续推出前瞻数字方案，驱动行业迈向数字新时代。</p>
          </div>
          <div class="feature-card">
            <h3>AI 智能</h3>
            <p>耕 AI 智能领域，集结顶尖专家。从算法优化、模型构建到场景拓展，覆盖智能客服、安防、营销等多场景，为客户定制全方位 AI 方案，助力企业借 AI 之力实现智能转型，提升竞争力。</p>
          </div>
          <div class="feature-card">
            <h3>精细服务</h3>
            <p>秉持 “微米级精细化服务”，深入了解企业痛点，量身定制服务。从咨询规划到开发运维，每个环节精雕细琢，以精准服务，助力企业在智能化升级之路稳健前行。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage'
}
</script>

<style scoped>

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.about-title {
  text-align: center;
  color: #333;
  font-size: 28px;
  margin-bottom: 50px;
}

.company-intro {
  background: #fff;
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.intro-content {
  margin-bottom: 50px;
}

.intro-text {
  font-size: 18px;
  line-height: 1.8;
  color: #444;
  text-align: justify;
}

.intro-text p {
  margin-bottom: 20px;
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.feature-card {
  background: #f8f9fa;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card h3 {
  color: #333;
  font-size: 20px;
  margin-bottom: 15px;
}

.feature-card p {
  color: #666;
  font-size: 16px;
  line-height: 1.6;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .about-container {
    padding: 40px 20px;
  }

  .company-intro {
    padding: 30px 20px;
  }

  .intro-text {
    font-size: 16px;
  }

  .feature-cards {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
</style>