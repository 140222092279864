<template>
  <header class="header">
    <div class="header-container">
      <div class="logo">
        <a href="#home" @click.prevent="scrollToSection('home')">
          <img src="@/assets/logo.png" alt="微米数字科技">
          <span class="company-name">微米数字科技</span>
        </a>
      </div>

      <nav class="nav-links" :class="{ 'active': isMenuOpen }">
        <a href="#home" 
           class="nav-item" 
           :class="{ 'active': activeSection === 'home' }" 
           @click.prevent="scrollToSection('home')">首页</a>
        <a href="#about" 
           class="nav-item" 
           :class="{ 'active': activeSection === 'about' }" 
           @click.prevent="scrollToSection('about')">关于我们</a>
        <a href="#project" 
           class="nav-item" 
           :class="{ 'active': activeSection === 'project' }" 
           @click.prevent="scrollToSection('project')">服务范围</a>   
        <a href="#team" 
           class="nav-item" 
           :class="{ 'active': activeSection === 'team' }" 
           @click.prevent="scrollToSection('team')">团队介绍</a>
        <a href="#contact" 
           class="nav-item" 
           :class="{ 'active': activeSection === 'contact' }" 
           @click.prevent="scrollToSection('contact')">联系我们</a>
      </nav>

      <div class="menu-toggle" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'SiteHeader',
  data() {
    return {
      isMenuOpen: false,
      activeSection: 'home'
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    scrollToSection(sectionId) {
      this.isMenuOpen = false
      const element = document.getElementById(sectionId)
      
      if (element) {
        const headerHeight = 80 // 头部高度
        const elementPosition = element.offsetTop - headerHeight
        
        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth'
        })
        
        // 更新活动部分
        this.activeSection = sectionId
      }
    },
    handleScroll() {
      const sections = ['home', 'about', 'project', 'team', 'contact']
      const scrollPosition = window.scrollY + 100 // 添加偏移量
      
      for (const section of sections) {
        const element = document.getElementById(section)
        if (element) {
          const elementTop = element.offsetTop
          const elementBottom = elementTop + element.offsetHeight
          
          if (scrollPosition >= elementTop && scrollPosition < elementBottom) {
            this.activeSection = section
            break
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.header {
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logo {
  display: flex;
  align-items: center;
}

.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.logo img {
  height: 40px;
}

.company-name {
  font-size: 20px;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 30px;
}

.nav-item {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.nav-item:hover {
  color: #007bff;
  background: rgba(0, 123, 255, 0.1);
}

.nav-item.active {
  color: #007bff;
  font-weight: 500;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
}

.menu-toggle span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #333;
  transition: all 0.3s ease;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .nav-links.active {
    display: flex;
  }

  .nav-item {
    padding: 12px;
    text-align: center;
  }
}
</style>