<template>
  <footer class="site-footer">
    <div class="footer-content">
      Copyright © 2023-{{ currentYear }} 贵州微米数字信息科技有限公司 | 版权所有
      <a href="https://beian.miit.gov.cn/" target="_blank" class="beian-link">
        备案号:黔ICP备2023008694号
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
.site-footer {
  padding: 20px 0;
  background-color: #f8f9fa;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  color: #666;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.beian-link {
  color: #666;
  text-decoration: none;
  margin-left: 5px;
}

.beian-link:hover {
  color: #333;
  text-decoration: underline;
}
</style> 