<template>
  <div class="team-page">
    <h1 class="team-title">团队成员</h1>
    <div class="team-container">
      <!-- 团队成员卡片 -->
      <div v-for="member in teamMembers" :key="member.id" class="member-card">
        <div class="member-avatar">
          <img :src="member.avatar" :alt="member.name">
        </div>
        <div class="member-info">
          <h3 class="member-name">{{ member.name }}</h3>
          <p class="member-position">{{ member.position }}</p>
          <div class="member-description">
            {{ member.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamPage',
  data() {
    return {
      teamMembers: [
        {
          id: 1,
          name: '雷军',
          position: '创始人 & CEO',
          description: '微米数字科技创始人，12 年扎根 IT 领域，深厚的技术底蕴，深耕数字化转型与 AI 应用。',
          avatar: require('@/assets/avatar-ceo.jpg')
        },
        {
          id: 2,
          name: '林宇',
          position: '技术总监',
          description: '10年软件研发经验，全栈开发专家，精通前后端技术，曾主导多个大型项目的架构设计与实施。',
          avatar: require('@/assets/avatar-cto.jpg')
        },
        {
          id: 3,
          name: '李天一',
          position: '产品总监',
          description: '8年产品从业经验，擅长洞察市场，以用户为中心，精准把握需求，从 0 到 1 构建产品架构。',
          avatar: require('@/assets/avatar-pm.jpg')
        },
        {
          id: 4,
          name: '刘一手',
          position: '设计总监',
          description: '7 年资深 UI/UX 设计经验，专注于为数字产品打造卓越用户体验，对交互逻辑、视觉美学及用户心理有深入洞察。',
          avatar: require('@/assets/avatar-ui.jpg')
        }
      ]
    }
  }
}
</script>

<style scoped>
.team-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.team-title {
  text-align: center;
  color: #333;
  margin-bottom: 50px;
  font-size: 28px;
}

.team-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  padding: 20px 0;
}

.member-card {
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.member-card:hover {
  transform: translateY(-5px);
}

.member-avatar {
  width: 120px;
  height: 120px;
  margin: 0 auto 20px;
  border-radius: 50%;
  overflow: hidden;
}

.member-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.member-info {
  text-align: center;
}

.member-name {
  font-size: 20px;
  color: #333;
  margin-bottom: 8px;
}

.member-position {
  color: #666;
  font-size: 16px;
  margin-bottom: 15px;
}

.member-description {
  color: #666;
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .team-container {
    grid-template-columns: 1fr;
  }
  
  .member-card {
    padding: 20px;
  }
}
</style>