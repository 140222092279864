<template>
    <div class="carousel-container">
      <div class="carousel">
        <!-- 轮播图片 -->
        <transition-group name="fade">
          <div v-for="(slide, index) in slides" 
               :key="slide.id" 
               v-show="currentIndex === index" 
               class="slide">
            <img :src="slide.image" :alt="slide.title">
            <div class="slide-content">
              <h2>{{ slide.title }}</h2>
              <p>{{ slide.description }}</p>
            </div>
          </div>
        </transition-group>
  
        <!-- 轮播指示器 -->
        <div class="carousel-indicators">
          <span v-for="(slide, index) in slides" 
                :key="slide.id"
                :class="{ active: currentIndex === index }"
                @click="setSlide(index)">
          </span>
        </div>
  
        <!-- 前后按钮 -->
        <button class="carousel-button prev" @click="prevSlide">❮</button>
        <button class="carousel-button next" @click="nextSlide">❯</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomeCarousel',
    data() {
      return {
        currentIndex: 0,
        slides: [
          {
            id: 1,
            title: '数字科技创新',
            description: '专注数字科技前沿，以创新驱动发展',
            image: require('@/assets/ad1.jpg')
          },
          {
            id: 2,
            title: 'AI 智能应用',
            description: '深耕 AI 智能领域，提供全方位解决方案',
            image: require('@/assets/ad2.jpg')
          },
          {
            id: 3,
            title: '智能化升级',
            description: '用数字与智能赋能企业，引领行业迈向新高度',
            image: require('@/assets/ad3.jpg')
          }
        ]
      }
    },
    mounted() {
      this.startSlideShow()
    },
    beforeUnmount() {
      this.stopSlideShow()
    },
    methods: {
      nextSlide() {
        this.currentIndex = (this.currentIndex + 1) % this.slides.length
      },
      prevSlide() {
        this.currentIndex = (this.currentIndex - 1 + this.slides.length) % this.slides.length
      },
      setSlide(index) {
        this.currentIndex = index
      },
      startSlideShow() {
        this.slideInterval = setInterval(this.nextSlide, 5000) // 5秒切换一次
      },
      stopSlideShow() {
        clearInterval(this.slideInterval)
      }
    }
  }
  </script>
  
  <style scoped>
  .carousel-container {
    width: 100%;
    height: 600px;
    overflow: hidden;
    position: relative;
  }
  
  .carousel {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .slide-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .slide-content h2 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .slide-content p {
    font-size: 24px;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 16px;
    border: none;
    cursor: pointer;
    font-size: 24px;
    transition: background 0.3s;
  }
  
  .carousel-button:hover {
    background: rgba(0, 0, 0, 0.6);
  }
  
  .prev {
    left: 20px;
  }
  
  .next {
    right: 20px;
  }
  
  .carousel-indicators {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
  }
  
  .carousel-indicators span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .carousel-indicators span.active {
    background: #fff;
  }
  
  /* 过渡动画 */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
  /* 响应式设计 */
  @media (max-width: 768px) {
    .carousel-container {
      height: 400px;
    }
  
    .slide-content h2 {
      font-size: 32px;
    }
  
    .slide-content p {
      font-size: 18px;
    }
  }
  </style>