<template>
  <div class="projects-section">
    <h1 class="projects-title">服务范围</h1>
    <div class="projects-grid">
      <div v-for="project in projects" :key="project.id" class="project-card">
        <img :src="project.image" :alt="project.title">
        <h3>{{ project.title }}</h3>
        <p>{{ project.description }}</p>
        <div class="project-links">
          <a :href="project.demo" target="_blank">演示</a>
          <a :href="project.github" target="_blank">GitHub</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Projects',
  data() {
    return {
      projects: [
        {
          id: 1,
          title: '微信生态',
          description: '专注微信公众号、微信小程序、企业微信等，致力于为企业和个人用户提供定制化的微信应用解决方案。',
          image: require('../assets/wechat-ad.jpg'),
          demo: 'https://demo1.com',
          github: 'https://github.com/yourusername/project1'
        },
        {
          id: 2,
          title: '网站建设',
          description: '专业的应用网站建设公司，无论是企业官网、电子商务平台还是信息发布网站，我们都能为您提供创意设计和高效开发，以及完善的用户体验。',
          image: require('../assets/website-ad.jpg'),
          demo: 'https://demo2.com',
          github: 'https://github.com/yourusername/project2'
        },
        {
          id: 3,
          title: 'APP建设',
          description: '专注于APP应用建设，提供高质量的定制开发服务，注重用户体验和应用性能，致力于为您提供功能丰富、界面精美的APP应用解决方案。',
          image: require('../assets/app-ad.jpg'),
          demo: 'https://demo1.com',
          github: 'https://github.com/yourusername/project1'
        },
        {
          id: 4,
          title: 'AI应用',
          description: '专注 AI 应用建设，以前沿技术为企业定制智能方案。深度融合 AI 于业务流程，提升客户体验与数据价值，助企业在数字化浪潮中转型升级，增强竞争力。',
          image: require('../assets/ai-ad.jpg'),
          demo: 'https://demo2.com',
          github: 'https://github.com/yourusername/project2'
        },
        {
          id: 5,
          title: '智能体搭建',
          description: '致力于 AI 智能体搭建，打造高个性化、交互性强的智能体。精准适配企业场景，从智能客服到决策辅助，为企业提供智能高效且创新的服务。',
          image: require('../assets/agent-ad.jpg'),
          demo: 'https://demo2.com',
          github: 'https://github.com/yourusername/project2'
        }
      ]
    }
  }
}
</script>

<style scoped>
.projects-section {
  padding: 20px 0;
}

.projects-title {
  text-align: center;
  color: #333;
  font-size: 28px;
  margin-bottom: 50px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.project-card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-card h3, .project-card p {
  padding: 15px;
  margin: 0;
}

.project-links {
  padding: 15px;
  display: flex;
  gap: 15px;
  display: none;
}

.project-links a {
  text-decoration: none;
  color: #42b883;
  font-weight: bold;
}
</style>