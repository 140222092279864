<template>

  <div class="contact-page">
    <h1 class="contact-title">联系我们</h1>
    <div class="contact-container">
      <!-- 左侧文字信息 -->
      <div class="contact-info">
        <div class="info-item">
          <h2>微信咨询</h2>
          <p class="wechat-id">微信号：xxjun9527</p>
          <p class="description">欢迎添加我们的企业微信，获取更多服务咨询。</p>
        </div>
        <div class="info-item">
          <h2>工作时间</h2>
          <p>周一至周五：9:00 - 18:00</p>
          <p>节假日：根据国家法定节假日安排</p>
        </div>
        <div class="info-item" style="display: none;">
          <h2>公司地址</h2>
          <p>贵州省贵阳市</p>
        </div>
      </div>
      
      <!-- 右侧二维码 -->
      <div class="qr-code-section">
        <div class="qr-code-container">
          <img src="@/assets/qr-code.png" alt="微信二维码" class="qr-code" />
          <p class="scan-text">扫码添加企业微信</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPage'
}
</script>

<style scoped>
.contact-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.contact-title {
  text-align: center;
  color: #333;
  margin-bottom: 50px;
  font-size: 28px;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 60px;
}

/* 左侧信息样式 */
.contact-info {
  flex: 1;
  padding-right: 40px;
}

.info-item {
  margin-bottom: 30px;
}

.info-item h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.info-item p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 8px;
}

.wechat-id {
  font-size: 18px;
  color: #333;
  font-weight: 500;
}

.description {
  color: #666;
  line-height: 1.6;
}

/* 右侧二维码样式 */
.qr-code-section {
  flex: 0 0 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code-container {
  text-align: center;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.qr-code {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.scan-text {
  margin-top: 15px;
  color: #666;
  font-size: 14px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    gap: 40px;
  }

  .contact-info {
    padding-right: 0;
  }

  .qr-code-section {
    flex: none;
    width: 100%;
  }
}
</style>