<template>
    <div id="app">
      <Header />
      <section id="home">
        <home-carousel></home-carousel>
      </section>
      <main>
        <section id="about">
          <About />
        </section>
        <section id="project">
          <Projects />
        </section>
        <section id="team">
          <Team />
        </section>
        <section id="contact">
          <Contact />
        </section> 
      </main>
      <site-footer></site-footer>
    </div>
  </template>
  
  <script>
  import Header from './components/Header.vue'
  import HomeCarousel from './components/HomeCarousel.vue'
  import About from './components/About.vue'
  import Projects from './components/Projects.vue'
  import Team from './components/Team.vue'
  import Contact from './components/Contact.vue'
  import SiteFooter from './components/SiteFooter.vue'
  
  export default {
    name: 'App',
    created() {
      document.title = '微米数字科技'
    },
    components: {
      Header,
      HomeCarousel,
      About,
      Projects,
      Team,
      Contact,
      SiteFooter
    }
  }
  </script>
  
  <style>
  body {
    margin: 0px;
  }
  #app {
    font-family: 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* 确保主要内容区域可以伸展，footer 始终在底部 */
  .router-view {
    flex: 1;
  }
  </style>